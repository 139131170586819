<template>
  <article class="user-section">
    <h1 class="text-secondary">
      Minha Conta
    </h1>

    <section class="profile">
      <nav class="profile__nav">
        <ul class="profile__tabs">
          <li
            v-for="(section, index) in sections"
            :key="`profile-tab-${index}`"
            :class="[{ 'profile__tabs-link--selected': $route.name === section.route }]"
            class="profile__tabs-link"
          >
            <router-link
              :to="{ name: section.route }"
              class="profile__link"
            >
              {{ section.name }}
            </router-link>
          </li>
        </ul>
      </nav>

      <article
        :class="{ 'profile__content--no-left-border': isFirstTab }"
        class="profile__content"
      >
        <router-view />
      </article>
    </section>
  </article>
</template>

<script>
export default {
  name: 'UserProfile',

  data() {
    return {
      sections: [
        {
          name: 'Meus dados',
          route: 'UserProfileData',
        },
        {
          name: 'Endereço',
          route: 'UserProfileAddress',
        },
        // {
        //   name: 'Alterar Senha',
        //   route: 'UserProfilePassword',
        // },
      ],
    };
  },

  computed: {
    isFirstTab() {
      return this.$route.name === this.sections[0].route;
    },
  },
};
</script>
